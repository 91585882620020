
import { MinorParticipant } from '@/common/types/MinorParticipant.type';
import YesNoInput from '@/modules/consent/components/form/yes-no-input.component.vue';
import MinorInfoForm from '@/modules/consent/components/minor-info-form.component.vue';
import { uuid } from '@/proxies/uuid.proxy';
import { kioskModule } from '@/store/kiosk.vuex-module';
import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'MinorCheckinView',
	components: { MinorInfoForm, YesNoInput },
})
export default class MinorCheckinView extends Vue {
	minors: MinorParticipant[] = [];
	minAge!: number;
	activityDate: Date | null = null;

	adultIsParticipating = kioskModule.consenter?.isParticipating ?? true;

	get adultName() {
		const fName = kioskModule.consenter?.firstName ?? '';
		const lName = kioskModule.consenter?.lastName ?? '';
		// return fName && lName ? `, ${fName.charAt(0).toUpperCase() + fName.slice(1)} ${lName.charAt(0).toUpperCase() + lName.slice(1)}, ` : ' ';
		return `<span>Are you, <span notranslate>${fName.charAt(0).toUpperCase() + fName.slice(1)} ${lName.charAt(0).toUpperCase() + lName.slice(1)}, </span> participating?</span>`;
	}

	// returns date of single activity order or null for multiple activities
	getActivityDate() {
		if (!kioskModule.order || kioskModule.order.CheckInOrderEntities.length != 1) return null;
		if (kioskModule.order.CheckInOrderEntities[0].CheckInOrderEntityTimes?.length) {
			const date = kioskModule.order.CheckInOrderEntities[0].CheckInOrderEntityTimes[0].AppointmentDate; // first appointment date
			if (date) {
				return new Date(date);
			}
		}
		return null;
	}

	mounted() {
		document.getElementById('checkinWrapper')?.scrollIntoView({ behavior: 'smooth' });
	}

	created(): void {
		kioskModule.updateLastPage('MinorsCheckin');
		if (kioskModule.order) {
			this.minAge = Math.min(...kioskModule.order.CheckInOrderEntities.map((e) => e.ChildMinAge));
			this.activityDate = this.getActivityDate();
		} else {
			this.minAge = 0;
		}

		this.minors = kioskModule.minorParticipants.filter((m) => !m.isDeleted);
		if (!this.minors.length) this.addNewMinor();
	}

	deleteMinor(guid: string): void {
		kioskModule.removeMinorParticipant(guid).then(() => {
			this.minors = kioskModule.minorParticipants.filter((m) => !m.isDeleted);
			if (!this.minors.length) this.addNewMinor();
		});
	}

	addNewMinor(): void {
		this.minors.push({
			firstName: '',
			lastName: '',
			email: '',
			dateOfBirth: {
				day: null,
				month: null,
				year: null,
			},
			guid: uuid.v4(),
			canDelete: true,
			customFields: kioskModule.minorCustomFields,
			skiExperienceKey: undefined,
			skiHeightKey: undefined,
			skiWeightKey: undefined,
			skiShoeSizeKey: undefined,
			isDeleted: false,
			consentMinorGuid: '',
		});
	}

	onNext() {
		let formValid = true;
		kioskModule.setAdultParticipantStatus(this.adultIsParticipating);
		this.minors.forEach((m, i) => {
			// call validate on all minor forms
			const minorElementMatches = this.$refs[`minor${i}`] as any;
			const minorForm = minorElementMatches[0] as MinorInfoForm;
			const valid = minorForm.validate();
			if (valid) {
				m.customFields = minorForm.customFields; // get any CF responses
				m.dateOfBirth.formatted = `${m.dateOfBirth.month! + 1}-${m.dateOfBirth.day!}-${m.dateOfBirth.year}`;
				kioskModule.addMinorParticipant(m);
			} else formValid = false;
		});
		if (formValid) {
			const route = kioskModule.unassignedWaiver ? 'WaiverForms' : 'ActivitySelection';
			this.$router.push({ name: route, query: { ...this.$route.query } });
		} else {
			// scroll first invalid field into view
			setTimeout(() => {
				document.getElementsByClassName('invalid')[0]?.scrollIntoView({ behavior: 'smooth' });
			});
		}
	}

	goBack() {
		this.$router.push({ name: 'AdultCheckin', query: { ...this.$route.query } });
	}
}
